import { cloneDeep } from 'lodash';
import stats from '@/src/util/stats.js';

import AccountsService from '@/src/common/services/accounts-service.js';
import TicketsService from '@/src/tickets/services/tickets-service.js';
import EndUsersService from '@/src/common/services/end-users-service.js';
import SnackbarStore from '@/src/common/store/snackbar-store.js';
import AppConfigStore from '@/src/common/store/app-config-store.js';
import RefreshGrowlerStore from '@/src/common/store/refresh-growler-store.js';
import KnowledgeBaseStore from '@/src/knowledge_base/store.js';
import { toCamelCase } from '@/src/util/service-mixins.js';
import TicketsLayoutOption from '@/src/tickets/tickets-layout-options.js';

const accountsService = new AccountsService();
const ticketsService = new TicketsService();
const endUsersService = new EndUsersService();

export function createAppStoreOptions(router) {
  return Object.freeze({
    state: createState(),
    getters: createGetters(),
    mutations: createMutations(),
    actions: createActions(router),
    modules: {
      knowledgeBase: KnowledgeBaseStore,
    },
  });
}

export function createState() {
  return {
    account: null,
    organizations: [],
    currentUserAccessibleOrganizations: [],
    customAttributes: [],
    ticketCategories: [],
    alerts: [],
    activities: [],
    ticketComments: [],
    currentTicket: null,
    isCurrentTicketFullyLoaded: true,
    currentTicketView: null,
    accountSubscriptionStatus: 'inactive',
    deviceReferences: [],
    tasks: [],
    tickets: [],
    addedTicketIDs: [],
    removedTicketIDs: [],
    watchers: [],
    endUsers: [],
    users: [],
    priorities: [
      { id: '1', name: 'High' },
      { id: '2', name: 'Medium' },
      { id: '3', name: 'Low' },
    ],
    statuses: [
      { id: 'open', name: 'Open' },
      { id: 'closed', name: 'Closed' },
      { id: 'waiting', name: 'Waiting' },
    ],
    meta: {},
    columns: [
      { key: 'id', name: 'ID', sort: '' },
      { key: 'summary', name: 'Summary', sort: '' },
      { key: 'assignee', name: 'Assignee', sort: '' },
      { key: 'creator', name: 'Creator', sort: '' },
      { key: 'organization', name: 'Organization', sort: '' },
      { key: 'priority', name: 'Priority', sort: '' },
      { key: 'category', name: 'Category', sort: '' },
      { key: 'status', name: 'Status', sort: '' },
      { key: 'created_at', name: 'Created', sort: '' },
      { key: 'updated_at', name: 'Updated', sort: '' },
      { key: 'due_at', name: 'Due Date', sort: '' },
      { key: 'first_response_secs', name: 'Response Time', sort: '' },
      { key: 'close_time_secs', name: 'Close Time', sort: '' },
    ],
    customAttributeColumns: [],
    columnsPreference: [],
    ticketViews: [],
    ticketsLivePoll: { new: [], updated: [] },
    ticketsAlertsLivePoll: [],
    appConfig: {},
    ticketsLoading: true,
    showColumnCheckboxList: false,
    ticketDataAbortController: new AbortController(),
    ticketAttributesExpanded:
      JSON.parse(window.localStorage.getItem('ticketAttributesExpanded')) ?? true,
    ticketsLayout:
      window.localStorage.getItem('ticketsLayout') ?? TicketsLayoutOption.HorizontalSplit,
    descriptionPinned: JSON.parse(window.localStorage.getItem('descriptionPinned')) ?? true,
    isAnyCommentUnderEdit: false,
    isShowingSummaryAndDescriptionDialog: false,
    isShowingDeleteCommentDialog: false,
    softDeleteTicketCommentCallback: null,
    ...SnackbarStore.state,
    ...RefreshGrowlerStore.state,
    ...AppConfigStore.state,
  };
}

export function createGetters() {
  return {
    creators: (state) => {
      return state.users.concat(state.endUsers);
    },

    columnsKeys: (state) => {
      const customAttributeIds = state.customAttributes.map((attr) => attr.id);
      return state.columns?.map((column) => column.key).concat(customAttributeIds);
    },

    activeAlerts: (state) => {
      // We only want the latest active alert for each ticket monitor/alert type
      const alerts = state.alerts.filter((alert) => alert.active).sort((a, b) => b.id - a.id);
      const typesSet = new Set();
      const result = [];
      alerts.forEach((alert) => {
        if (typesSet.has(alert.type)) return;
        typesSet.add(alert.type);
        result.push(alert);
      });

      return result;
    },

    accountSubscriptionStatus: (state) => {
      return state.accountSubscriptionStatus;
    },

    ...AppConfigStore.getters,
  };
}

export function createMutations() {
  return {
    ADD_END_USER(state, endUser) {
      state.endUsers.push(endUser);
    },

    INITIALIZE_APP_CONFIG(state, appConfig) {
      state.appConfig = toCamelCase(appConfig);
    },

    SET_COLUMNS_PREFERENCE(state, columnsPreference) {
      state.columnsPreference = columnsPreference;
    },

    SET_ACCOUNT(state, account) {
      state.account = account;
    },

    SET_ORGANIZATIONS(state, organizations) {
      state.organizations = organizations;
    },

    SET_CURRENT_USER_ACCESSIBLE_ORGANIZATIONS(state, organizations) {
      const clonedOrganizations = cloneDeep(organizations);

      const currentUser = toCamelCase(window.CURRENT_USER);
      const restrictedOrganizationIds = currentUser?.restrictedOrganizationIds;

      if (!currentUser || !organizations) {
        state.currentUserAccessibleOrganizations = [];
      } else if (
        currentUser.role !== 'admin' &&
        restrictedOrganizationIds &&
        restrictedOrganizationIds.length > 0
      ) {
        state.currentUserAccessibleOrganizations = clonedOrganizations
          .filter((organziation) => restrictedOrganizationIds.includes(organziation.id))
          .sort((a, b) => a.name.localeCompare(b.name));
      } else {
        state.currentUserAccessibleOrganizations = clonedOrganizations.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      }
    },

    SET_TICKET_CATEGORIES(state, ticketCategories) {
      state.ticketCategories = ticketCategories;
    },

    SET_CUSTOM_ATTRIBUTES(state, customAttributes) {
      if (!customAttributes || !state?.organizations) {
        return;
      }
      // sort custom attributes alphabetically by organization name
      state.customAttributes = customAttributes.sort((a, b) => {
        const orgAName = state.organizations.find((org) => org.id === a.organizationId).name;
        const orgBName = state.organizations.find((org) => org.id === b.organizationId).name;
        return orgAName.localeCompare(orgBName);
      });

      // Set custom attribute table columns
      state.customAttributeColumns = state.customAttributes.map((attr) => ({
        key: attr.id,
        name: attr.name.trim(),
        organizationId: attr.organizationId,
        sort: '',
      }));
    },

    SET_ALERTS(state, alerts) {
      state.alerts = alerts;
    },

    SET_ACTIVITIES(state, activities) {
      state.activities = activities;
    },

    SET_TICKET_COMMENTS(state, ticketComments) {
      state.ticketComments = ticketComments;
    },

    UPDATE_TICKET_COMMENT_AND_ACTIVITY(state, updatedComment) {
      // Update in ticketComments
      const commentIndex = state.ticketComments.findIndex(
        (comment) => comment.id === updatedComment.id
      );
      if (commentIndex !== -1) {
        state.ticketComments.splice(commentIndex, 1, updatedComment);
      }

      // Update in activities
      const activityIndex = state.activities.findIndex(
        (activity) =>
          activity.id === updatedComment.id &&
          (activity.activityType === 'comment' || activity.activityType === 'note')
      );
      if (activityIndex !== -1) {
        state.activities.splice(activityIndex, 1, {
          ...state.activities[activityIndex],
          ...updatedComment,
        });
      }
    },

    SET_CURRENT_TICKET(state, ticket) {
      state.currentTicket = ticket;
    },

    SET_IS_CURRENT_TICKET_FULLY_LOADED(state, isCurrentTicketFullyLoaded) {
      state.isCurrentTicketFullyLoaded = isCurrentTicketFullyLoaded;
    },

    SET_DEVICE_REFERENCES(state, deviceReferences) {
      state.deviceReferences = deviceReferences;
    },

    SET_TASKS(state, tasks) {
      state.tasks = tasks;
    },

    SET_TICKETS(state, tickets) {
      state.tickets = tickets;
    },

    SET_ADDED_TICKET_IDS(state, ticketIDs) {
      state.addedTicketIDs = ticketIDs;
    },

    SET_REMOVED_TICKET_IDS(state, ticketIDs) {
      state.removedTicketIDs = ticketIDs;
    },

    SET_WATCHERS(state, watchers) {
      state.watchers = watchers;
    },

    SET_TICKET_ATTRIBUTES_EXPANDED(state, ticketAttributesExpanded) {
      state.ticketAttributesExpanded = ticketAttributesExpanded;
      window.localStorage.setItem('ticketAttributesExpanded', ticketAttributesExpanded);
    },

    SET_DESCRIPTION_PINNED(state, descriptionPinned) {
      state.descriptionPinned = descriptionPinned;
      window.localStorage.setItem('descriptionPinned', descriptionPinned);
      stats.track('ticket description pin', 'ticket details');
    },

    SET_TICKETS_LAYOUT(state, ticketsLayout) {
      state.ticketsLayout = ticketsLayout;
      window.localStorage.setItem('ticketsLayout', ticketsLayout);
    },

    SET_TICKETS_META(state, ticketsMeta) {
      state.meta = ticketsMeta;
    },

    SET_CURRENT_TICKET_VIEW(state, ticketView) {
      state.currentTicketView = ticketView;
    },

    SET_TICKET_VIEWS(state, ticketViews) {
      state.ticketViews = ticketViews;
    },

    SET_END_USERS(state, endUsers) {
      state.endUsers = endUsers;
    },

    SET_USERS(state, users) {
      state.users = users;
    },

    SET_TICKETS_LOADING(state, areTicketsLoading) {
      state.ticketsLoading = areTicketsLoading;
    },

    SET_SHOW_COLUMN_CHECKBOX_LIST(state, shouldShowColumnCheckboxList) {
      state.showColumnCheckboxList = shouldShowColumnCheckboxList;
    },

    SET_TICKET_DATA_ABORT_CONTROLLER(state, ticketDataAbortController) {
      state.ticketDataAbortController = ticketDataAbortController;
    },

    SET_TICKET_LIVE_POLL(state, ticketLivePoll) {
      // Prevent duplicates
      if (state.ticketsLivePoll[ticketLivePoll.state].indexOf(ticketLivePoll.id) === -1) {
        state.ticketsLivePoll[ticketLivePoll.state].push(ticketLivePoll.id);
      }
    },

    REMOVE_TICKET_LIVE_POLL(state, ticketId) {
      const n = state.ticketsLivePoll.new.indexOf(ticketId);
      const u = state.ticketsLivePoll.updated.indexOf(ticketId);

      if (n >= 0) {
        state.ticketsLivePoll.new.splice(n, 1);
      }

      if (u >= 0) {
        state.ticketsLivePoll.updated.splice(u, 1);
      }
    },

    SET_LOCAL_TICKET_HAS_CHANGED(state, opts) {
      const i = state.tickets.findIndex((ticket) => ticket.id === opts.id);

      state.tickets[i] = {
        ...state.tickets[i],
        hasChanged: opts.hasChanged,
        liveState: opts.liveState,
      };
    },

    SET_ALERTS_TO_LOCAL_TICKET(state, ticketAlertPoll) {
      const i = state.tickets.findIndex((ticket) => ticket.id === ticketAlertPoll.id);

      state.tickets[i] = { ...state.tickets[i], alerts: ticketAlertPoll.alerts };

      // Keep a record in ticketsAlertsLivePoll and prevent duplicates
      if (
        state.ticketsAlertsLivePoll.findIndex((alert) => alert.id === ticketAlertPoll.id) === -1 &&
        ticketAlertPoll.alerts.length > 0
      ) {
        state.ticketsAlertsLivePoll.push(ticketAlertPoll);
      }
    },

    REMOVE_TICKETS_ALERTS_LIVE_POLL(state, ticketId) {
      const i = state.ticketsAlertsLivePoll.findIndex((alert) => alert.id === ticketId);

      if (i >= 0) {
        state.ticketsAlertsLivePoll.splice(i, 1);
      }
    },

    SET_IS_ANY_COMMENT_UNDER_EDIT(state, isAnyCommentUnderEdit) {
      state.isAnyCommentUnderEdit = isAnyCommentUnderEdit;
    },

    SET_IS_SHOWING_SUMMARY_AND_DESCRIPTION_DIALOG(state, isShowingSummaryAndDescriptionDialog) {
      state.isShowingSummaryAndDescriptionDialog = isShowingSummaryAndDescriptionDialog;
    },

    SET_IS_SHOWING_DELETE_COMMENT_DIALOG(state, isShowingDeleteCommentDialog) {
      state.isShowingDeleteCommentDialog = isShowingDeleteCommentDialog;
    },

    SET_SOFT_DELETE_TICKET_COMMENT_CALLBACK(state, callback) {
      state.softDeleteTicketCommentCallback = callback;
    },

    SET_ACCOUNT_SUBSCRIPTION_STATUS(state, { accountSubscriptionStatus }) {
      state.accountSubscriptionStatus = accountSubscriptionStatus;
    },

    ...SnackbarStore.mutations,
    ...RefreshGrowlerStore.mutations,
    ...AppConfigStore.mutations,
  };
}

export function createActions(router) {
  return {
    initializeAppConfig(context, appConfig) {
      context.commit('INITIALIZE_APP_CONFIG', appConfig);
    },

    initializeLocalStorageData(context) {
      let columnsPreference;

      // Prevent crash if the localStorage was corrupted
      try {
        columnsPreference = JSON.parse(
          window.localStorage.getItem('ticketColumnsPreference') || '[]'
        );
      } catch (_e) {
        columnsPreference = [];
      }

      context.dispatch('setColumnsPreference', columnsPreference);
    },

    updateMeta(context, { totalCountIncrement, endRangeOffsetIncrement }) {
      const meta = context.state.meta;
      const newTotalCountOffset = meta?.totalCountOffset
        ? meta.totalCountOffset + totalCountIncrement
        : totalCountIncrement;
      const newTotalCount = meta.totalCount + newTotalCountOffset;

      if (meta?.totalCount && newTotalCount >= 0) {
        context.commit('SET_TICKETS_META', {
          currentPage: meta.currentPage,
          totalCount: meta.totalCount,
          totalPages: meta.totalPages,
          totalCountOffset: newTotalCountOffset,
          endRangeOffset: meta?.endRangeOffset
            ? meta.endRangeOffset + endRangeOffsetIncrement
            : endRangeOffsetIncrement,
        });
      }
    },

    async addTicketToTicketsList(context, { ticket, listTicket = {} }) {
      if (!ticket || listTicket?.id) return false;

      // Ticket has already been added to the view
      const addedTicketIDs = context.state.addedTicketIDs;
      const addedTicketID = addedTicketIDs.find((id) => {
        return id === ticket.id;
      });
      if (addedTicketID) {
        return false;
      }

      // update addTicketIDs
      addedTicketIDs.push(ticket.id);
      context.commit('SET_ADDED_TICKET_IDS', addedTicketIDs);

      // If we aren't adding the record to the current page we still
      // want to update meta.totalCount
      const isFirstPage = parseInt(router.currentRoute.value.params.page) === 1;
      if (!isFirstPage) {
        await context.dispatch('updateMeta', {
          totalCountIncrement: 1,
          endRangeOffsetIncrement: 0,
        });
        return false;
      }

      // Add the ticket as the first ticket in tickets store
      // Avoiding sorting for now.
      const tickets = context.state.tickets;
      tickets.unshift(cloneDeep(ticket));

      context.commit('SET_TICKETS', tickets);
      await context.dispatch('updateMeta', {
        totalCountIncrement: 1,
        endRangeOffsetIncrement: 1,
      });

      // update removedTicketIDs if needed
      const removedTicketIDs = context.state.removedTicketIDs;
      const removedTicketIndex = removedTicketIDs.indexOf(ticket.id);
      if (removedTicketIndex >= 0) {
        removedTicketIDs.splice(removedTicketIndex, 1);
        context.commit('SET_REMOVED_TICKET_IDS', removedTicketIDs);
      }

      return true;
    },

    async removeTicketFromTicketsList(context, ticketID) {
      const tickets = context.state.tickets;
      const ticketToRemove = tickets.find((ticket) => {
        return ticket.id === ticketID;
      });

      // Ticket has already been removed from the view
      const removedTicketIDs = context.state.removedTicketIDs;
      const removedTicketID = removedTicketIDs.find((id) => {
        return id === ticketID;
      });
      if (removedTicketID) {
        return false;
      }

      // update addedTicketIDs if needed
      const addedTicketIDs = context.state.addedTicketIDs;
      const addedTicketIndex = addedTicketIDs.indexOf(ticketID);
      if (addedTicketIndex >= 0) {
        addedTicketIDs.splice(addedTicketIndex, 1);
        context.commit('SET_ADDED_TICKET_IDS', addedTicketIDs);
      }

      // If we aren't removing the record to the current page we still
      // want to update meta.totalCount
      if (!ticketToRemove) {
        await context.dispatch('updateMeta', {
          totalCountIncrement: -1,
          endRangeOffsetIncrement: 0,
        });
        removedTicketIDs.push(ticketID);
        context.commit('SET_REMOVED_TICKET_IDS', removedTicketIDs);
        return false;
      }

      const ticketToRemoveIndex = tickets.indexOf(ticketToRemove);

      if (ticketToRemoveIndex >= 0) {
        tickets.splice(tickets.indexOf(ticketToRemove), 1);
        context.commit('SET_TICKETS', tickets);
        await context.dispatch('updateMeta', {
          totalCountIncrement: -1,
          endRangeOffsetIncrement: -1,
        });
        removedTicketIDs.push(ticketID);
        context.commit('SET_REMOVED_TICKET_IDS', removedTicketIDs);
        return true;
      }

      return false;
    },

    // returns a boolean to reduce looking up a ticket from the tickets list again in
    // updateTicketRow when no changes were made to the existing tickets in the currentTicketView
    async updateTicketsInTicketView(context, { ticket, listTicket }) {
      const filter = context.state.currentTicketView?.filter,
        statusFilter = filter?.status,
        ticketStatus = ticket?.status;

      // We are only updating tickets in TicketViews with
      // TicketViewCondtion status checks
      if (!statusFilter || !ticketStatus || ticketStatus === listTicket?.status) {
        return false;
      }

      const isValidEqualFilter = statusFilter?.eq && statusFilter.eq === ticketStatus;
      const isValidNotEqualFilter = statusFilter?.ne && statusFilter.ne === ticketStatus;
      const isSingleTicketViewCondition = Object.keys(filter).length === 1;

      // To remove a Ticket from a TicketView we just need to break
      // a single status TicketViewCondition. This should work for
      // TicketViews with multiple TicketViewConditions.
      if (isValidEqualFilter === false || isValidNotEqualFilter) {
        return await context.dispatch('removeTicketFromTicketsList', ticket.id);
      } else if (
        isSingleTicketViewCondition &&
        (isValidEqualFilter || isValidNotEqualFilter === false)
      ) {
        return await context.dispatch('addTicketToTicketsList', {
          ticket,
          listTicket,
        });
      }

      return false;
    },

    async updateTicketRow(context, ticket = {}) {
      const listTicket = ticket?.id ? context.state.tickets.find((t) => t.id === ticket.id) : null;

      const ticketViewUpdates = await context.dispatch('updateTicketsInTicketView', {
        ticket,
        listTicket,
      });

      // we need to find the ticketList object again since there were
      // TicketView updates to context.state.tickets
      if (ticketViewUpdates) {
        const newListTicket = context.state.tickets.find((t) => t.id === ticket.id);

        if (newListTicket) {
          Object.assign(newListTicket, ticket);
        }
      } else if (listTicket) {
        Object.assign(listTicket, ticket);
      }
    },

    async getAccountData(context, accountId) {
      const {
        account,
        organizations,
        ticketCategories,
        customAttributes,
        users,
        accountSubscriptions,
      } = await accountsService.getAccount(accountId);
      context.commit('SET_ACCOUNT', account);
      context.commit('SET_ORGANIZATIONS', organizations);
      context.commit('SET_CURRENT_USER_ACCESSIBLE_ORGANIZATIONS', organizations);
      context.commit('SET_TICKET_CATEGORIES', ticketCategories);
      context.commit('SET_CUSTOM_ATTRIBUTES', customAttributes);
      context.commit('SET_USERS', users);
      if (accountSubscriptions && accountSubscriptions.length > 0) {
        context.commit('SET_ACCOUNT_SUBSCRIPTION_STATUS', {
          accountSubscriptionStatus: accountSubscriptions[0].subscriptionStatus,
        });
      }
    },

    async refreshTickets(context) {
      // This prevents resetCurrentTicketData() from eager loading
      // state.currentTicket from state.tickets which we are about
      // to refresh
      await context.commit('SET_TICKETS', []);

      context.dispatch('getTickets');
      context.dispatch('getCurrentTicket');
    },

    async getTickets(context, isMobile = false) {
      try {
        context.dispatch('setTicketsLoading', true);
        const { params, query } = router.currentRoute.value;
        const { tickets, meta } = await ticketsService.getTicketsList({
          page: {
            number: params.page || 1,
            size: isMobile ? 15 : 50,
          },
          filter: context.state.currentTicketView?.filter || null,
          sort: query.sort,
          q: query.q,
        });

        context.commit('SET_TICKETS', tickets);
        context.commit('SET_TICKETS_META', meta);
        context.commit('SET_ADDED_TICKET_IDS', []);
        context.commit('SET_REMOVED_TICKET_IDS', []);
      } catch (error) {
        context.dispatch('showSnackbar', { display: true, type: 'error', message: error });
      } finally {
        context.dispatch('setTicketsLoading', false);
      }
    },

    async getCurrentTicket(context) {
      const ticketNumber = router.currentRoute.value.query.ticket_number;
      const newTicketDataAbortController = new AbortController();
      await context.commit('SET_IS_CURRENT_TICKET_FULLY_LOADED', false);

      // abort previous network requests for currentTicket
      context.state.ticketDataAbortController.abort();
      await context.commit('SET_TICKET_DATA_ABORT_CONTROLLER', newTicketDataAbortController);

      await Promise.all([
        context.dispatch('resetCurrentTicketData'),
        context.dispatch('getTicketDetails', ticketNumber),
        context.dispatch('getAlerts', ticketNumber),
        context.dispatch('getActivities', ticketNumber),
        context.dispatch('getTicketComments', ticketNumber),
      ]).catch(async (error) => {
        // Only throw errors when the network requests aren't terminated by
        // the user navigating between tickets quickly
        if (!newTicketDataAbortController?.signal?.aborted) {
          await context.commit('SET_IS_CURRENT_TICKET_FULLY_LOADED', true);
          throw error;
        }
      });

      // Don't set isCurrentTicketFullyLoaded when aborted
      if (!newTicketDataAbortController?.signal?.aborted) {
        await context.commit('SET_IS_CURRENT_TICKET_FULLY_LOADED', true);
      }
    },

    async resetCurrentTicketData(context) {
      const ticketID = router.currentRoute.value.query.ticket_number;
      const newCurrentTicket = context.state.tickets.find((ticket) => {
        return ticket.id.toString() === ticketID;
      });

      // Eager loading the currentTicket from tickets list allows the
      // user to see ticket description and other core attributes before
      // activities and ticket comments load in
      if (newCurrentTicket) {
        context.commit('SET_CURRENT_TICKET', cloneDeep(newCurrentTicket));
      } else {
        context.commit('SET_CURRENT_TICKET', null);
      }

      context.commit('SET_ALERTS', []);
      context.commit('SET_DEVICE_REFERENCES', []);
      context.commit('SET_TASKS', []);
      context.commit('SET_WATCHERS', []);
      context.commit('SET_ACTIVITIES', []);
      context.commit('SET_TICKET_COMMENTS', []);
    },

    async getTicketDetails(context, ticketNumber) {
      try {
        const response = await ticketsService.getTicketDetails(
          ticketNumber,
          context.state.ticketDataAbortController.signal
        );

        if (
          ticketNumber &&
          String(ticketNumber) === String(router.currentRoute.value.query.ticket_number)
        ) {
          context.dispatch('setTicketDetails', response);
        }
      } catch (error) {
        context.commit('SET_CURRENT_TICKET', null);
        throw error;
      }
    },

    async getAlerts(context, ticketNumber) {
      const response = await ticketsService.getAlerts(
        ticketNumber,
        context.state.ticketDataAbortController.signal
      );

      if (
        ticketNumber &&
        String(ticketNumber) === String(router.currentRoute.value.query.ticket_number)
      ) {
        context.commit('SET_ALERTS', response.alerts || []);
      }
    },

    async getActivities(context, ticketNumber) {
      const response = await ticketsService.getActivities(
        ticketNumber,
        context.state.ticketDataAbortController.signal
      );

      if (
        ticketNumber &&
        String(ticketNumber) === String(router.currentRoute.value.query.ticket_number)
      ) {
        context.commit('SET_ACTIVITIES', response.activities || []);
      }
    },

    async getTicketComments(context, ticketNumber) {
      const ticketComments = ticketsService.getTicketComments(
        ticketNumber,
        context.state.ticketDataAbortController.signal
      );
      const response = await ticketComments;

      if (
        ticketNumber &&
        String(ticketNumber) === String(router.currentRoute.value.query.ticket_number)
      ) {
        context.commit('SET_TICKET_COMMENTS', response.ticketComments || []);
      }
    },

    async updateTicketComment(context, payload) {
      const response = await ticketsService.updateTicketComment(
        payload.id,
        payload.ticketCommentParams
      );

      const updatedComment = response.comment;

      context.commit('UPDATE_TICKET_COMMENT_AND_ACTIVITY', updatedComment);

      return response;
    },

    async softDeleteTicketComment(context, payload) {
      const response = await ticketsService.softDeleteTicketComment(
        payload.id,
        payload.ticketCommentParams.id
      );

      if (response && response.comment) {
        context.commit('UPDATE_TICKET_COMMENT_AND_ACTIVITY', response.comment);
      }

      return response;
    },

    async undoEditTicketComment(context, payload) {
      const response = await ticketsService.undoEditTicketComment(
        payload.id,
        payload.ticketCommentParams
      );

      const updatedComment = response.comment;

      context.commit('UPDATE_TICKET_COMMENT_AND_ACTIVITY', updatedComment);

      return response;
    },

    async updateTicketUnlessLoading(context, payload) {
      if (
        payload?.id.toString() === router.currentRoute.value.query.ticket_number &&
        !context.state.isCurrentTicketFullyLoaded
      ) {
        throw 'Current ticket is still loading';
      }

      return await ticketsService.updateTicket(payload);
    },

    async getTicketViews(context) {
      const ticketViews = await ticketsService.getTicketViews();
      context.commit('SET_TICKET_VIEWS', ticketViews);
      context.dispatch('updateDocumentTitle');
    },

    async setCurrentTicketView(context) {
      if (context.state.ticketViews.length === 0) {
        await context.dispatch('getTicketViews');
      }

      const ticketView =
        context.state.ticketViews.find(
          (view) => view.name_path === router.currentRoute.value.params.view
        ) || context.state.ticketViews[0];

      context.commit('SET_CURRENT_TICKET_VIEW', ticketView);
      context.dispatch('updateDocumentTitle');
    },

    async getEndUsers(context) {
      const { endUsers } = await endUsersService.getEndUsers();
      context.commit('SET_END_USERS', endUsers);
    },

    setTicketsLoading(context, areTicketsLoading) {
      context.commit('SET_TICKETS_LOADING', areTicketsLoading);
    },

    async setShowColumnCheckboxList(context, shouldShowColumnCheckboxList) {
      await context.commit('SET_SHOW_COLUMN_CHECKBOX_LIST', shouldShowColumnCheckboxList);
    },

    setColumnsPreference(context, columnsPreference) {
      if (
        !columnsPreference ||
        !columnsPreference?.length ||
        ['[]', 'undefined'].includes(columnsPreference)
      ) {
        columnsPreference = context.getters.columnsKeys;
      }

      window.localStorage.setItem('ticketColumnsPreference', JSON.stringify(columnsPreference));
      context.commit('SET_COLUMNS_PREFERENCE', columnsPreference);
    },

    setCurrentTicket(context, ticket) {
      context.commit('SET_CURRENT_TICKET', ticket);
    },

    clearCurrentTicket(context) {
      context.commit('SET_CURRENT_TICKET', null);
    },

    setTicketDetails(context, response) {
      const ticket = response.ticket;

      // The tickets table uses api/main/tickets while the ticketDetails uses
      // api/main/tickets/# which return different payloads. When updating the
      // ticketDetails we need to update currentTicket wholesale and update
      // specific attributes of the same ticket found in the tickets object.
      context.commit('SET_CURRENT_TICKET', ticket);
      context.dispatch('updateTicketRow', ticket);
      context.dispatch('updateDocumentTitle');

      // TODO: Move these to seperate API calls
      context.commit('SET_DEVICE_REFERENCES', response.deviceReferences || []);
      context.commit('SET_TASKS', response.tasks || []);
      context.commit('SET_WATCHERS', response.watchers || []);
    },

    setTicketAttributesExpanded(context, ticketAttributesExpanded) {
      context.commit('SET_TICKET_ATTRIBUTES_EXPANDED', ticketAttributesExpanded);
    },

    setDescriptionPinned(context, descriptionPinned) {
      context.commit('SET_DESCRIPTION_PINNED', descriptionPinned);
    },

    setTicketsLayout(context, ticketsLayout) {
      context.commit('SET_TICKETS_LAYOUT', ticketsLayout);
    },

    async setTicketsLayoutAndReroute(context, ticketsLayout) {
      const { params, query } = router.currentRoute.value;
      if (query.ticket_number && ticketsLayout === TicketsLayoutOption.NoSplit) {
        await router.replace({
          name: 'Tickets',
          params,
          query: { ...query, ticket_number: undefined },
        });
      } else if (!query.ticket_number && ticketsLayout !== TicketsLayoutOption.NoSplit) {
        await router.replace({
          name: 'Tickets',
          params,
          query: { ...query, ticket_number: context.state.tickets[0]?.id },
        });
      }

      context.commit('SET_TICKETS_LAYOUT', ticketsLayout);
    },

    updateTicketDetails(context, response) {
      context.dispatch('setTicketDetails', response);
      context.dispatch('getActivities', response.ticket.id);
    },

    // **********************
    //   LIVE POLL FEATURE
    // **********************
    addTicketLivePoll(context, ticketLivePoll) {
      context.commit('SET_TICKET_LIVE_POLL', ticketLivePoll);
    },

    removeTicketLivePoll(context, ticketId) {
      context.commit('REMOVE_TICKET_LIVE_POLL', ticketId);
    },

    // Update the state for one of the tickets in the store
    setLocalTicketChanged(context, opts) {
      context.commit('SET_LOCAL_TICKET_HAS_CHANGED', {
        id: opts.id,
        hasChanged: true,
        liveState: opts.liveState,
      });
    },

    // Reset the state for one of the tickets in the store
    clearLocalTicketChanged(context, ticketId) {
      context.commit('SET_LOCAL_TICKET_HAS_CHANGED', {
        id: ticketId,
        hasChanged: false,
        liveState: null,
      });

      context.commit('REMOVE_TICKET_LIVE_POLL', ticketId);
    },

    // Store all live alerts
    setAlertsToLocalTicket(context, ticketAlertPoll) {
      context.commit('SET_ALERTS_TO_LOCAL_TICKET', ticketAlertPoll);
    },

    // Clear the alerts for local tickets
    clearAlertsToLocalTicket(context, ticketId) {
      context.commit('SET_ALERTS_TO_LOCAL_TICKET', { id: ticketId, alerts: [] });
      context.commit('REMOVE_TICKETS_ALERTS_LIVE_POLL', ticketId);
    },

    clearLivePollDataForTicket(context, ticketId) {
      context.dispatch('clearLocalTicketChanged', ticketId);
      context.dispatch('clearAlertsToLocalTicket', ticketId);
    },
    // ************ END ************

    addEndUser(context, endUser) {
      try {
        context.commit('ADD_END_USER', endUser);
      } catch (_e) {
        throw 'Something went wrong, try reloading the page.';
      }
    },

    updateDocumentTitle(context) {
      // We need to check both currentTicket and ticket_number because when we hide the ticket details we
      // do not nullify currentTicket state.
      if (context.state.currentTicket && router.currentRoute.value.query.ticket_number) {
        document.title = `#${context.state.currentTicket.id} - ${context.state.currentTicket.summary} - Spiceworks Help Desk`;
      } else if (context.state.currentTicketView) {
        document.title = `${context.state.currentTicketView.name} Tickets - Spiceworks Help Desk`;
      } else {
        document.title = 'Tickets - Spiceworks Help Desk';
      }
    },

    setIsAnyCommentUnderEdit(context, isAnyCommentUnderEdit) {
      context.commit('SET_IS_ANY_COMMENT_UNDER_EDIT', isAnyCommentUnderEdit);
    },

    setIsShowingSummaryAndDescriptionDialog(context, isShowingSummaryAndDescriptionDialog) {
      context.commit(
        'SET_IS_SHOWING_SUMMARY_AND_DESCRIPTION_DIALOG',
        isShowingSummaryAndDescriptionDialog
      );
    },

    setIsShowingDeleteCommentDialog(context, value) {
      context.commit('SET_IS_SHOWING_DELETE_COMMENT_DIALOG', value);
    },

    setSoftDeleteTicketCommentCallback(context, callback) {
      context.commit('SET_SOFT_DELETE_TICKET_COMMENT_CALLBACK', callback);
    },

    async updateTasks(context, updatedTasks) {
      // Hack to make sure the tasks are updated in the UI immediately, bad practice if errors occur
      const allTasks = [...context.state.tasks];
      updatedTasks.forEach((updatedTask) => {
        const index = allTasks.findIndex((task) => task.id === updatedTask.id);
        if (index !== -1) {
          allTasks[index] = updatedTask;
        }
      });
      allTasks.sort((a, b) => a.position - b.position);
      context.commit('SET_TASKS', allTasks);
      await Promise.all(updatedTasks.map((task) => ticketsService.updateTicketTask(task.id, task)));
    },

    async updateTask(context, updatedTask) {
      const allTasks = [...context.state.tasks];
      const index = allTasks.findIndex((task) => task.id === updatedTask.id);
      if (index !== -1) {
        allTasks[index] = updatedTask;
      }
      allTasks.sort((a, b) => a.position - b.position);
      context.commit('SET_TASKS', allTasks);
      await ticketsService.updateTicketTask(updatedTask.id, updatedTask);
    },

    ...SnackbarStore.actions,
    ...RefreshGrowlerStore.actions,
    ...AppConfigStore.actions,
  };
}
